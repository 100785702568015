import { useQuery } from '@apollo/react-hooks';
import { useCustomerQuery } from '@jetshop/core/components/Auth/useCustomer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import { TrendButton } from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import React from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';
import clubImage from '../../images/the-club.jpg';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import customerNameQuery from '../MyPages/CustomerNameQuery.gql';
import { useYotpoLoyalty } from '../Yotpo/YotpoLoyalty';
import CartHeader from './CartHeader';
import ProfilePageLoggedOutQuery from './queries/ProfilePageLoggedOutQuery.gql';

const Links = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-bottom: auto;

  a {
    margin 5px 0;
    color: #000000;
    text-decoration: none;
  }
`;

const LoginForm = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  margin-top: 20px;

  button,
  a {
    margin: 0 auto;
    text-transform: uppercase;
  }

  a {
    color: #000;
    text-decoration: underline !important;
  }
`;

const Image = styled(`img`)`
  width: 100%;
  height: 200px;
  object-position: center;
  object-fit: cover;
`;

const Content = styled('div')`
  ul {
    display: flex;

    li {
      margin: 0 10px;

      &:before {
        content: '✓';
        margin-right: 5px;
      }
    }
  }
`;

const Welcome = styled(`h3`)`
  padding: 5px 10px;
  border-bottom: 1px solid #eaeaea;
`;

const LoggedOut = ({ setActivePage, activePage, onClose, itemsInCart }) => {
  const { data } = useQuery(ProfilePageLoggedOutQuery, {
    variables: {
      id: 430
    }
  });

  const content = data?.category?.content;

  return (
    <>
      <CartHeader
        activePage={activePage}
        setActivePage={setActivePage}
        onClose={onClose}
        itemsInCart={itemsInCart}
      >
        <h2>{t('Log in')}</h2>
      </CartHeader>

      <Image src={clubImage} />

      <LogInFormProvider>
        {({ globalError, isSubmitting, isValid }) => (
          <LoginForm>
            <Input type="email" name="email" label={t('E-mail address')} />
            <Input type="password" name="password" label={t('Password')} />

            {globalError && <GlobalError>{globalError}</GlobalError>}

            <TrendButton
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
              loadingText={t('Hold on a moment...')}
            >
              {t('Log in')}
            </TrendButton>

            <Link to="/signup" onClick={onClose}>
              {t('Join the club')}
            </Link>
          </LoginForm>
        )}
      </LogInFormProvider>

      {content && (
        <Marquee gradient={false} style={{ marginTop: 'auto' }}>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </Marquee>
      )}
    </>
  );
};

const LoggedIn = ({ setActivePage, onClose, itemsInCart, activePage }) => {
  const { customer } = useCustomerQuery({ query: customerNameQuery });
  const useLoyalty = useYotpoLoyalty();

  if (!customer) return null;

  return (
    <>
      <CartHeader
        activePage={activePage}
        setActivePage={setActivePage}
        onClose={onClose}
        itemsInCart={itemsInCart}
      >
        <h2>{t('My pages')}</h2>
      </CartHeader>

      <Welcome>
        {t(`Hi, {firstName} {lastName}`, {
          firstName: customer.billingAddress.firstName,
          lastName: customer.billingAddress.lastName
        })}
      </Welcome>

      <Links>
        <Link to="/my-pages" onClick={onClose}>
          {t('Go to my pages')}
        </Link>
        <Link to="/my-pages/orders" onClick={onClose}>
          {t('My orders')}
        </Link>
        <Link to="/my-pages/profile" onClick={onClose}>
          {t('My profile')}
        </Link>
        {useLoyalty && (
          <Link to="/the-club" onClick={onClose}>
            {t('Club Bread & Boxers')}
          </Link>
        )}
      </Links>

      <Image src={clubImage} />
    </>
  );
};

const ProfilePage = ({ cart, ...rest }) => {
  const { loggedIn } = useAuth();

  const itemsInCart = cart?.data?.cart?.totalQuantity || 0;

  if (loggedIn) return <LoggedIn itemsInCart={itemsInCart} {...rest} />;

  return <LoggedOut itemsInCart={itemsInCart} {...rest} />;
};

export default ProfilePage;
