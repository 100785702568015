import React from 'react';
import NavTree from './NavTree';

export default function NavTreePage() {
  return (
    <div>
      <NavTree />
    </div>
  );
}
