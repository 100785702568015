import React from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import { ContactQuery } from './ContactQuery.gql';
import get from 'lodash.get';

const Content = styled('div')`
  color: black;

  font-weight: 300;
  line-height: 25px;
  text-align: center;

  p {
    a: hover {
      color: #000;
    }
  }

  address {
    font-style: inherit;
  }
`;

const StoreContact = () => (
  <Query query={ContactQuery}>
    {({ data }) => {
      const category = get(data, 'category');
      return category ? (
        <Content dangerouslySetInnerHTML={{ __html: category.content }} />
      ) : null;
    }}
  </Query>
);

export default StoreContact;
