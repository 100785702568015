import { useProductListItems } from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { CART_PAGES } from '.';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { ReactComponent as Heart } from '../../svg/Heart.svg';
import { ReactComponent as Person } from '../../svg/Person.svg';
import { ReactComponent as Cart } from '../../svg/ShoppingBag.svg';
import { Badge } from './CartActions';

const ActivePage = css`
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    bottom: -11.5px;
    left: 0;
  }
`;

const HeaderActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const StyledCartHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  color: #000;
  padding: 10px;

  h2 {
    font-size: 1.25rem;
    font-weight: 300;
    text-transform: uppercase;
  }

  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;

    use {
      fill: #000000;
    }
  }
`;

const IconButton = styled(`div`)`
  position: relative;
  cursor: pointer;
`;

const CartHeader = ({
  onClose,
  setActivePage,
  children,
  itemsInCart,
  activePage
}) => {
  const { products } = useProductListItems();

  return (
    <StyledCartHeader>
      {children}

      <HeaderActions>
        <IconButton
          onClick={() => setActivePage(CART_PAGES.FAVOURITES)}
          className={cx(activePage === CART_PAGES.FAVOURITES && ActivePage)}
        >
          <Heart>{t('Favourites')}</Heart>
          {products?.length > 0 && (
            <Badge className="favourites-badge">{products?.length}</Badge>
          )}
        </IconButton>
        <IconButton
          onClick={() => setActivePage(CART_PAGES.PROFILE)}
          className={cx(activePage === CART_PAGES.PROFILE && ActivePage)}
        >
          <Person>{t('Profile')}</Person>
        </IconButton>

        <IconButton
          onClick={() => setActivePage(CART_PAGES.CART)}
          className={cx(activePage === CART_PAGES.CART && ActivePage)}
        >
          <Cart>{t('Cart')}</Cart>
          {itemsInCart > 0 && (
            <Badge className="cart-badge">{itemsInCart}</Badge>
          )}
        </IconButton>

        <Cross onClick={onClose}>Close</Cross>
      </HeaderActions>
    </StyledCartHeader>
  );
};

export default CartHeader;
