import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React, { Fragment } from 'react';
import { theme } from '../Theme';

const Description = styled('article')`
  color: black;

  font-size: 14px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 0rem;
  width: 100%;
  ${theme.below.md} {
    margin-top: 1rem;
  }
  iframe {
    margin: 1rem 0;
    width: 100%;
  }
  img {
    margin: 1rem 0;
    max-width: 100%;
    text-align: center;
  }
  b,
  strong {
    font-weight: bold;
  }
`;

export const SectionHeading = styled('h2')`
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 1rem;
  margin-top: 0 !important;
`;

export const ProductSection = styled('section')`
  padding-right: 2rem;
  width: 65%;
  padding-bottom: 3rem;

  ${theme.below.lg} {
    padding-left: 1rem;
  }
  ${theme.below.md} {
    width: 100%;
    padding: 0;
    padding-bottom: 1rem;
  }
`;

const ProductInfo = ({ product }) => {
  return (
    <Fragment>
      {product?.description && (
        <SectionHeading>{t('Product description')}</SectionHeading>
      )}
      <Description
        dangerouslySetInnerHTML={{
          __html: product?.description
        }}
      />
    </Fragment>
  );
};

export default ProductInfo;
