import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

import { theme } from '../../Theme';

import { ReactComponent as Instagram } from '../../../svg/instagram.svg';
import { ReactComponent as Youtube } from '../../../svg/youtube.svg';
import { ReactComponent as Twitter } from '../../../svg/twitter.svg';
import { ReactComponent as Facebook } from '../../../svg/facebook.svg';

const Wrapper = styled('section')`
  margin-right: 0;
  margin-bottom: 2rem;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    ${theme.above.md} {
      margin-top: 0;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  li {
    margin: 0 0.5rem;
    padding: 0;
    :last-of-type {
      margin-right: 0;
    }
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }

  color: ${theme.colors.black};
`;

const LogoWrapper = styled('span')`
  :hover {
    color: #000;
  }
  color: black;
  width: 2.375rem;
  height: 2.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 15%;
  transition: all 0.3s;
  ${theme.below.md} {
    margin-right: 0.25rem;
  }
  svg.svg-inline--fa {
    height: 2.375rem;
    width: 2.375rem;
  }
`;

const SocialLinks = () => (
  <Wrapper>
    <h2>{t('Social media')}</h2>
    <ul>
      <li>
        <a href="https://www.instagram.com/breadandboxers/">
          <LogoWrapper>
            <Instagram />
          </LogoWrapper>
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCHVsPLd987J3h3_m68cxvOQ">
          <LogoWrapper>
            <Youtube />
          </LogoWrapper>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/breadandboxers">
          <LogoWrapper>
            <Twitter />
          </LogoWrapper>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/breadandboxers/">
          <LogoWrapper>
            <Facebook />
          </LogoWrapper>
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
