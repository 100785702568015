import { useQuery } from '@apollo/react-hooks';
import t from '@jetshop/intl';
import ProductLink from '@jetshop/ui/ProductLink';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../Theme';
import { SectionHeading } from '../ProductInfo';
import { productColors } from '../SizeSelector';
import productColorsQuery from './productColorsQuery.gql';

const StyledProductLink = styled(ProductLink)`
  background-color: ${props => (props.color ? props.color : '#fff')};
  border: 1px solid #ddd;
  color: black;
  display: inline-block;
  font-size: ${props => (props.color ? '0px' : '1rem')};
  font-weight: 300;
  height: 40px;
  justify-content: center;
  line-height: 36px;
  margin: 5px;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  transition: all ease 0.3s;
  width: 40px;
  align-items: center;
  justify-content: center;

  .hover-color {
    background: #eaeaea;
    bottom: 110%;
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 85%,
      60% 85%,
      50% 100%,
      40% 85%,
      0 85%
    );
    color: #000;
    display: block;

    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    margin-left: -40px;
    margin-top: -55px;
    opacity: 0;
    padding: 0.5rem 0rem 0.8rem 0rem;
    position: absolute;
    transition: all ease 0.5s;
    width: 120px;
    pointer-events: none;

    ${theme.below.sm} {
      display: none;
    }
  }
  &:hover .hover-color {
    opacity: 1;
  }
  &:hover {
    box-shadow: 1px 1px 5px #555;
  }
`;

const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px 0;

  a {
    display: block;
    border: 1px solid #eaeaea;
    position: relative;
    transition: all ease 0.3s;
    &:hover {
      box-shadow: 1px 1px 5px #555;
    }
    &.active {
      box-shadow: 1px 1px 5px #555;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
        width: 100%;
        background: black;
      }
    }
  }

  &.category-colors {
    justify-content: center;
    margin-top: -5px;
    ${StyledProductLink} {
      height: 18px;
      width: 18px;
      margin: 9px 3px;
      line-height: 20px;
      .hover-color {
        margin: auto;
        position: absolute;
        left: 50%;
        bottom: 100%;
        top: auto;
        transform: translateX(-50%);
      }
      ${theme.below.md} {
        height: 18px;
        width: 18px;
        margin: 9px 3px;
      }
    }
  }
`;

function checkArticleNumber(productArtNo, colorArtNo) {
  const artNo = productArtNo.split('-')[0];
  const colorArt = colorArtNo.split('-')[0];

  if (artNo === colorArt) {
    return true;
  }
}

function ProductColors({
  productArtNo,
  colorName,
  categoryPage,
  updateActiveProduct
}) {
  const term = productArtNo?.split('-')[0];

  const { loading, error, data } = useQuery(productColorsQuery, {
    variables: {
      term
    }
  });

  if (loading || error) {
    return null;
  }

  const products = data?.search?.products?.result;
  const isCategoryPage = categoryPage;

  if (!products) {
    return null;
  }

  const sortedColorProducts = products
    .filter(p => checkArticleNumber(productArtNo, p.articleNumber))
    .sort((a, b) => {
      const [, aCol] = a.articleNumber.split('-');
      const [, bCol] = b.articleNumber.split('-');
      return Number(aCol) > Number(bCol);
    });

  return (
    <>
      {!isCategoryPage ? (
        <SectionHeading>
          {t('Color')}: {colorName}
        </SectionHeading>
      ) : null}
      <Container className={isCategoryPage ? 'category-colors' : null}>
        {sortedColorProducts.map(product => {
          const color =
            productColors[product.subName.toLowerCase().replace(/\s/g, '-')];
          if (color) {
            return (
              <StyledProductLink
                onMouseLeave={() => updateActiveProduct?.(null)}
                onMouseEnter={() => updateActiveProduct?.(product)}
                key={product.id}
                color={color}
                product={product}
                className={
                  productArtNo === product.articleNumber ? 'active' : null
                }
              >
                <div className="hover-color">{product.subName}</div>
              </StyledProductLink>
            );
          }
          return null;
        })}
      </Container>
    </>
  );
}

export default ProductColors;
