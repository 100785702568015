import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import Carousel from 'my-react-carousel';
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo
} from 'react';
import { ReactComponent as Chevron } from '../../svg/Chevron.svg';
import GridProduct from '../CategoryPage/GridProduct';
import throttle from 'lodash.throttle';

const ProductStyle = css`
  width: 100%;
`;

export const CarouselContainer = styled.section`
  width: calc(100% - 50px);
  margin: 0 auto;
  position: relative;

  > svg {
    position: absolute;
    top: 20%;
    margin: auto;
    cursor: pointer;
    width: 25px;
    height: 25px;

    &:first-child {
      left: -25px;
      transform: rotate(90deg);
    }

    &:last-child {
      right: -25px;
      transform: rotate(-90deg);
    }
  }
`;

const CarouselWrapper = ({
  hasPrevious,
  carouselContainerRef,
  hasNext,
  next,
  previous,
  pauseCarousel,
  children
}) => {
  const throttledNext = useMemo(
    () => throttle(next, 1200, { trailing: false }),
    [next]
  );
  const throttledPrevious = useMemo(
    () => throttle(previous, 1200, { trailing: false }),
    [previous]
  );

  return (
    <CarouselContainer ref={carouselContainerRef}>
      {hasPrevious && (
        <Chevron
          onClick={() => {
            throttledPrevious();
            pauseCarousel();
          }}
        />
      )}
      {children}
      {hasNext && (
        <Chevron
          onClick={() => {
            throttledNext();
            pauseCarousel();
          }}
        />
      )}
    </CarouselContainer>
  );
};

const ProductsCarousel = ({
  products,
  isLoading,
  productsToShow = 3,
  autoPlay = true,
  onClick
}) => {
  const carouselRef = useRef();
  const timeoutRef = useRef();
  const pauseRef = useRef();
  const carouselContainerRef = useRef();

  const [stopAutoPlay, setStopAutoPlay] = useState(false);

  const autoplay = products?.length > productsToShow && autoPlay;

  const scrollCarousel = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      if (carouselRef.current) {
        carouselRef.current.next();
      }
      scrollCarousel();
    }, 4000);
  }, []);

  const pauseCarousel = () => {
    setStopAutoPlay(true);
    clearTimeout(timeoutRef.current);
    clearTimeout(pauseRef.current);

    pauseRef.current = setTimeout(() => {
      setStopAutoPlay(false);
    }, 5000);
  };

  useEffect(() => {
    if (autoplay && !stopAutoPlay) {
      scrollCarousel();
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [autoplay, stopAutoPlay, scrollCarousel]);

  // Disable swipe and touch actions for slider.
  useEffect(() => {
    const carouselContainer = carouselContainerRef.current;

    const preventSwipe = e => {
      e.preventDefault();
      e.stopImmediatePropagation();
    };

    if (carouselContainer && autoPlay) {
      carouselContainer.addEventListener('touchmove', preventSwipe);
      carouselContainer.addEventListener('mousemove', preventSwipe);
    }

    return () => {
      if (carouselContainer && autoPlay) {
        carouselContainer.removeEventListener('touchmove', preventSwipe);
        carouselContainer.removeEventListener('mousemove', preventSwipe);
      }
    };
  }, [autoPlay]);

  return (
    <Carousel
      transitionDuration={1000}
      ref={carouselRef}
      slidesToShow={productsToShow}
      infinite={autoplay}
      render={({ slides, hasPrevious, previous, hasNext, next }) => {
        return (
          <CarouselWrapper
            pauseCarousel={pauseCarousel}
            previous={previous}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            next={next}
            carouselContainerRef={carouselContainerRef}
          >
            {slides}
          </CarouselWrapper>
        );
      }}
    >
      {products?.map(product => (
        <div
          onMouseEnter={() => setStopAutoPlay(true)}
          onMouseLeave={() => setStopAutoPlay(false)}
        >
          <GridProduct
            loading={isLoading}
            product={product}
            className={cx(ProductStyle)}
            onClick={onClick}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default ProductsCarousel;
