import { useRef, useState, useEffect } from 'react';

export function usePromise(fn, dependencies) {
  const active = useRef({ promise: null });
  const [state, setState] = useState({
    done: false,
    result: undefined
  });

  useEffect(() => {
    const promise = Promise.resolve(undefined)
      .then(fn)
      .then(
        result => {
          if (active.current.promise !== promise) return;
          setState({ done: true, result });
        },
        error => {
          if (active.current.promise !== promise) return;
          setState({ done: false, error, result: undefined });
          throw error;
        }
      );

    active.current.promise = promise;
    setState({ done: false, result: undefined });
    return () => (active.current.promise = undefined);
  }, dependencies || []);

  if (state.error) {
    throw state.error;
  }
  return [state.done, state.result];
}
