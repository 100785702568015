import { useLocation } from 'react-router';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PreviewRoute from '@jetshop/core/components/DynamicRoute/PreviewRoute';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import NavTreePage from './NavigationTree/NavTreePage';
import NotFound from './NotFoundPage';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import loadFontCss from '../fonts/loadFontCss';
import { theme } from './Theme';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { productListQueries } from './ProductList/productListQueries';

import '../globalStyles';
import YotpoSeoPage from './Yotpo/YotpoSeoPage';
import YotpoUGC from './Yotpo/YotpoUGC';

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});
const LoadableJournal = loadable(() => import('./Pages/Journal'), {
  fallback: <LoadingPage />
});

const LoadableJournalContent = loadable(
  () => import('./Pages/JournalContent'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableRewardsPage = loadable(() => import('./Yotpo/RewardsPage'), {
  fallback: <LoadingPage />
});

const yotpoGalleries = {
  '/about-bb': '6357ac734d372c0020193abe',
  '/terms': '6357ae3bc4cd39298b676083',
  '/contact': '6357b13d4d372c0020194d79',
  '/jobb': '6357b2014d372c001d193ab8',
  '/butiker': '6357b23e0539791e375def9b',
  '/return-policy': '6357b28170f7957727abfd34',
  '/login': '6357b2b86eb6491d813c4253',
  '/sale': '6357b31b5f3f791eba82f02d',
  '/search': '6357b36670f7957727abff1a'
};

function Shop() {
  const location = useLocation();

  return (
    <GenericError>
      <ModalProvider>
        <ProductListProvider queries={productListQueries}>
          <Container>
            <LoadingBar />
            <CustomFont
              primaryFont={theme.fonts.primary}
              injectCss={loadFontCss}
            />
            <Helmet
              titleTemplate="%s - Bread &amp; Boxers"
              defaultTitle="Bread &amp; Boxers"
            />
            <Header />
            <Content>
              <PaginationProvider defaultProductsPerPage={99}>
                <Switch>
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path="/search" component={LoadableSearchPage} />
                  <Route path="/login" component={LogInPage} />
                  <Route path="/tree" component={NavTreePage} />
                  <Route path="/my-pages" component={LoadableMyPages} />
                  <Route path="/signup" component={LoadableSignUpPage} />
                  <Route
                    path="/journal/:slug"
                    component={LoadableJournalContent}
                  />
                  <Route path="/journal" component={LoadableJournal} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />

                  <Route
                    path="/bread-and-boxers-reviews"
                    component={YotpoSeoPage}
                  />
                  <Route
                    path="/bread-and-boxers-omdomen"
                    component={YotpoSeoPage}
                  />
                  <Route
                    path="/bread-and-boxers-reviews"
                    component={YotpoSeoPage}
                  />
                  <Route
                    path="/bread-and-boxers-anmeldelser"
                    component={YotpoSeoPage}
                  />
                  <Route
                    path="/bread-and-boxers-rezensionen"
                    component={YotpoSeoPage}
                  />

                  <Route path="/the-club" component={LoadableRewardsPage} />

                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={LoadableCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
              {yotpoGalleries[location?.pathname] && (
                <YotpoUGC galleryId={yotpoGalleries[location?.pathname]} />
              )}
            </Content>
            <Footer />
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
            />
          </Container>
        </ProductListProvider>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
