import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import React from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';
import CloseButton from '../../ui/CloseButton';
import pagesQuery from '../Footer/PagesQuery.gql';
import MobileCategories from './Categories/MobileCategories';

const StyledCloseButton = styled(CloseButton)`
  background: transparent;
  cursor: pointer;
  right: 2rem;
  outline: none;
  position: absolute;
  top: 48px;
`;

const Title = styled('h1')`
  background: #fff;

  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-top: 45px;
  margin-bottom: 2rem;
  text-align: center;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
`;

const SecondaryMenu = styled.ul`
  margin: 0;
  padding: 0 1rem;
  display: block;

  font-weight: 300;
  font-size: 14px;

  .auth {
    background: #000;
    color: #fff;
    display: block;
    margin: auto;
    margin-bottom: 1rem;
    padding: 0.75rem 2.5rem;
    text-align: center;
    transition: all ease 0.3s;
    width: 75%;
    :hover {
      background: #333;
      color: #fff;
    }
  }

  ${theme.below.sm} {
    flex-direction: column;
    flex: 0 0 auto;
  }
`;

const SecondaryMenuItem = styled.li`
  list-style: none;
  display: block;
  margin-top: 0;
  padding: 1em 1em 0.9375em;
  border-bottom: 1px solid #eee;
  :first-of-type {
    margin-top: 2rem;
  }
  :last-of-type {
    border: none;
    margin-bottom: 2rem;
  }
  a,
  a:active {
    text-decoration: none;
    color: #000;
  }
`;

export default function MobileMenu({ data, isOpen, setIsOpen }) {
  const { loggedIn, logOut } = useAuth();
  if (!(data && data.categories)) return null;

  return (
    <DrawerTarget id="menu-drawer">
      {() => (
        <Drawer isOpen={isOpen}>
          <Title>{t('Menu')}</Title>
          <StyledCloseButton onClick={() => setIsOpen(false)} />

          <Scroll>
            <MobileCategories
              categories={data.categories}
              closeMenu={() => setIsOpen(false)}
            />

            <SecondaryMenu>
              <Query query={pagesQuery}>
                {({ data }) => {
                  const pages = data?.pages;

                  return pages ? (
                    <>
                      <SecondaryMenuItem>
                        <Link to="/" onClick={() => setIsOpen(false)}>
                          {t('Start page')}
                        </Link>
                      </SecondaryMenuItem>
                      {pages.map(page => (
                        <SecondaryMenuItem key={page.id}>
                          <Link
                            onClick={() => setIsOpen(false)}
                            to={page.primaryRoute.path}
                          >
                            {page.name}
                          </Link>
                        </SecondaryMenuItem>
                      ))}
                      {loggedIn ? (
                        <SecondaryMenuItem>
                          <Link to="/my-pages" onClick={() => setIsOpen(false)}>
                            {t('My pages')}
                          </Link>
                        </SecondaryMenuItem>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : null;
                }}
              </Query>

              {loggedIn ? (
                <span
                  className="auth"
                  onClick={() => {
                    logOut();
                    setIsOpen(false);
                  }}
                >
                  {t('Log out')}
                </span>
              ) : (
                <Link
                  to="/login"
                  onClick={() => setIsOpen(false)}
                  className="auth"
                >
                  {t('Log in')}
                </Link>
              )}
            </SecondaryMenu>
          </Scroll>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
