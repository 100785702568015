import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0;
  position: relative;
  flex: 1 1 auto;
  max-width: 72.5rem;

  ${theme.below.sm} {
    max-width: 100%;
    padding: 0rem 0.5rem 0rem 0.5rem !important;
  }
`;

const MaxWidth = ({ className = '', children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default MaxWidth;
