import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { usePromise } from '../../hooks/usePromise';
import { useInView } from 'react-intersection-observer';
import { RatingStars } from './RatingStars';
import { useYotpoApiKey } from './Yotpo';

export const RatingWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  div {
    display: flex;
  }
  span {
    margin-left: 4px;
    font-size: 9px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    background-color: rgb(238, 238, 238);
    border-radius: 120px;
    padding: 2px 3px;
    min-width: 16px;
    text-align: center;
  }

  svg {
    margin: 0 0.5px;
  }

  ${theme.below.lg} {
    svg {
      font-size: 0.55rem;
      margin: 0 1px;
    }
  }

  opacity: 1;
  animation: enter 500ms ease-out;
  @keyframes enter {
    0% {
      opacity: 0;
    }
  }
`;

const ProductGridRatingsWrapper = styled('div')`
  min-height: 15px;
`;

const ProductGridRatingsInner = ({ id }) => {
  const yotpoKey = useYotpoApiKey();
  const url = `https://api-cdn.yotpo.com/v1/widget/${yotpoKey}/products/${id}/reviews.json?per_page=0`;
  const [ready, data] = usePromise(async () => {
    try {
      const res = await fetch(url);
      if (res.status !== 200) return;
      return await res.json();
    } catch (ex) {
      return;
    }
  }, [id]);

  if (!ready || !data?.response?.bottomline?.total_review) return null;
  const stars = data.response.bottomline?.average_score || 0;
  //const count = data.response.bottomline?.total_review || 0;
  return (
    <RatingWrapper className="not-product-page">
      <RatingStars stars={stars} />
      {/* <div>
        <span>{count}</span>
      </div> */}
    </RatingWrapper>
  );
};

export const ProductGridRatings = ({ id }) => {
  const { ref, inView } = useInView({});
  return (
    <ProductGridRatingsWrapper ref={ref}>
      {inView ? <ProductGridRatingsInner id={id} /> : null}
    </ProductGridRatingsWrapper>
  );
};
