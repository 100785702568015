import React, { useContext } from 'react';
import { CART_PAGES } from '.';
import { ReactComponent as Heart } from '../../svg/Heart.svg';
import { ReactComponent as Person } from '../../svg/Person.svg';
import { ReactComponent as Cart } from '../../svg/ShoppingBag.svg';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { useProductListItems } from '@jetshop/core/hooks/ProductList';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import CartQuery from './queries/CartQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from '../Theme';

export const Badge = styled('span')`
  background: #000;
  color: white;
  font-size: 9px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  text-align: center;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: -5px;
  right: -5px;
  font-weight: 300;

  &.animate {
    &:after {
      z-index: -1;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      animation: pulse 1s ease infinite;
      border-radius: 50%;
      background: black;
    }
    
    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(1.5);
        opacity: 0;
      }
  }
`;

const StyledCartActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  ${theme.above.lg} {
    width: 30%;
  }

  svg {
    width: 22px;
    height: 22px;
    cursor: pointer;

    ${theme.below.md} {
      height: 25px;
      width: 25px;
    }

    use {
      fill: #000000;
    }
  }
`;

const CartButton = styled('div')`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const CartSum = styled('span')`
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  margin-right: 5px;
  height: 16px;

  ${theme.below.md} {
    display: none;
  }

  &.animate {
    animation: flash .4s ease-in-out alternate 4;
  
    @keyframes flash {
      from {
        text-shadow: 0 0 0 black;
      }
      to {
        text-shadow: 0 0 2px black;
      }
    }
`;

const IconButton = styled(`div`)`
  position: relative;
  cursor: pointer;
`;

const CartActions = ({ setActivePage, setIsOpen }) => {
  const { products } = useProductListItems();
  const { result } = useCart(CartQuery);
  const { selectedChannel } = useContext(ChannelContext);

  const sumOfCart = result?.data?.cart?.productTotal?.incVat || 0;

  const VAT = new Intl.NumberFormat(selectedChannel.defaultLanguage.culture, {
    style: 'currency',
    currency: selectedChannel.currency.name
  }).format(sumOfCart);

  const itemsInCart = result.data?.cart?.totalQuantity || 0;

  return (
    <StyledCartActions>
      <CartButton
        onClick={() => {
          setActivePage(CART_PAGES.CART);
          setIsOpen(true);
        }}
      >
        <CartSum className="cart-sum">{VAT}</CartSum>

        <IconButton>
          <Cart>{t('Cart')}</Cart>
          {itemsInCart > 0 && (
            <Badge className="cart-badge">{itemsInCart}</Badge>
          )}
        </IconButton>
      </CartButton>

      <IconButton
        onClick={() => {
          setActivePage(CART_PAGES.FAVOURITES);
          setIsOpen(true);
        }}
      >
        <Heart>{t('Favourites')}</Heart>
        {products?.length > 0 && (
          <Badge className="favourites-badge">{products?.length}</Badge>
        )}
      </IconButton>

      <IconButton
        onClick={() => {
          setIsOpen(true);
          setActivePage(CART_PAGES.PROFILE);
        }}
      >
        <Person>{t('Profile')}</Person>
      </IconButton>
    </StyledCartActions>
  );
};

export default CartActions;
