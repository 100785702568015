import get from 'lodash.get';
import React from 'react';
import { Query } from 'react-apollo';
import pagesQuery from './PagesQuery.gql';
import ContentPageLink from '@jetshop/ui/ContentPageLink';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

const FooterLinks = () => {
  const { loggedIn } = useAuth();
  return (
    <Query query={pagesQuery}>
      {({ data }) => {
        const pages = get(data, 'pages');

        return pages ? (
          <section style={{ marginBottom: '1.75rem' }}>
            <h2>Info</h2>
            <ul>
              <li>
                <Link to={'/'}>{t('Start page')}</Link>
              </li>
              {pages.map(page => (
                <li key={page.id}>
                  <ContentPageLink page={page}>{page.name}</ContentPageLink>
                </li>
              ))}
              <li>
                {loggedIn ? (
                  <Link to={'/my-pages'}>{t('My pages')}</Link>
                ) : (
                  <Link to="/login">{t('Log in')}</Link>
                )}
              </li>
              <li>
                <Link to={'/about-bb'}>{t('About us')}</Link>
              </li>
            </ul>
          </section>
        ) : null;
      }}
    </Query>
  );
};

export default FooterLinks;
