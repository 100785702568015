import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React, { Component, Fragment } from 'react';
import Selector from './Selector';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';

import { ReactComponent as Globe } from '../../../../svg/globe.svg';

const Flyout = styled('div')`
  background-color: #fff;
  cursor: auto;
  position: absolute;
  right: 0.5rem;
  z-index: 5;
  top: 3rem;
  width: 19rem;
  ${theme.below.xs} {
    width: 15rem;
  }
`;

const International = styled('span')`
  height: 20px;
  margin-right: 5px;
  width: 20px;

  svg.svg-inline--fa {
    color: white;
    height: 17px;
    width: 17px;
  }
`;

const CountryFlag = styled('img')`
  height: 20px;
  margin-right: 5px;
`;

const ChannelBtn = styled('button')`
  background: transparent;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: -2px;
  margin-right: 8px;
  outline: none;
  padding: 0 0.5rem;
  text-align: right;
  ${theme.below.md} {
    margin-right: 0px !important;
    justify-content: flex-end;
  }
  span {
    height: initial;
  }
  ${theme.below.xs} {
    margin: 0.35rem 0;
  }
`;

const ChannelName = styled('span')`
  ${theme.below.md} {
    display: none;
  }
`;

export default class HeaderChannelSelector extends Component {
  render() {
    return (
      <ChannelContext.Consumer>
        {({ channels, selectedChannel, updateChannel }) => (
          <Fragment>
            <FlyoutTrigger id="channel-selector">
              {({ showTarget, hideTarget, isOpen }) => (
                <ChannelBtn onClick={isOpen ? hideTarget : showTarget}>
                  {selectedChannel.id === 3 ? (
                    <International>
                      <Globe />
                    </International>
                  ) : (
                    <CountryFlag
                      src={`https://countryflags.jetshop.io/${selectedChannel.country.code}/flat/32.png`}
                      alt="Country Flag"
                    />
                  )}

                  <ChannelName>
                    {selectedChannel.id === 3
                      ? 'International'
                      : selectedChannel.country.name}
                  </ChannelName>
                </ChannelBtn>
              )}
            </FlyoutTrigger>
            <FlyoutTarget id="channel-selector">
              {({ isOpen, hideTarget }) => (
                <Flyout>
                  <Selector
                    channels={channels}
                    hideTarget={hideTarget}
                    selectedChannel={selectedChannel}
                    updateChannel={updateChannel}
                  />
                </Flyout>
              )}
            </FlyoutTarget>
          </Fragment>
        )}
      </ChannelContext.Consumer>
    );
  }
}
