import { useContext, useEffect } from 'react';
import { useScript } from '../../hooks/useScript';
import { useWaitForTrue } from '../../hooks/useWaitForTrue';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const apiKey = {
  // SE
  1: 'nU0BNZs0RRtS7Rn1jfxpIg5K9nUmqiAoE8yVdX6v',
  // EU
  3: 'RrOdDxDRfEAVFEsCmYGnzftvK8jq4fmGeU6j5iFx',
  // NO
  4: 'Br9pGut4RQBoUKP21U0Gj4y7K4jlqUa82DKOc1Ts',
  // DE
  5: '6lQerIUnFC9QBUGEQN0lBSRMpJNWg0Um74CRB1au',
  // US
  7: 'XfdbejoVzRuVAEd0fZLl94fQrXIiDJ6FqXkMFoVF'
};

export const useYotpoApiKey = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return apiKey[selectedChannel.id];
};

export const useYotpoWidget = () => {
  const yotpoKey = useYotpoApiKey();
  const state = useScript(`https://staticw2.yotpo.com/${yotpoKey}/widget.js`);
  const done = useWaitForTrue(
    () => state === 'ready' && typeof yotpo !== 'undefined'
  );
  useEffect(() => {
    if (!done) return;
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.yotpo.refreshWidgets();
      }
    }, 100);
  }, [done]);
  return done;
};
