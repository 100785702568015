import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import { InfoTextQuery } from './InfoTextQuery.gql';
import get from 'lodash.get';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Marquee from 'react-fast-marquee';

const Content = styled('div')`
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 400;
  width: 100%;
  padding: 0 20px;
  p {
    text-align: left;

    a {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  }
`;

const InfoText = () => {
  const { selectedChannel } = useContext(ChannelContext);

  const textID = () => {
    switch (selectedChannel.id) {
      case 1: // SE
        return 350;
      case 2: // DK
        return 347;
      case 3: // EU
        return 345;
      case 4: // NO
        return 349;
      case 5: // DE
        return 346;
      case 7: // US
        return 351;
      case 8: // UK
        return 352;
      case 9: // FR
        return 348;
      case 15: // NL
        return 437;

      default:
        return 342;
    }
  };

  return (
    <Query query={InfoTextQuery} variables={{ id: textID() }}>
      {({ data }) => {
        const category = get(data, 'category');
        return category ? (
          <Marquee
            gradient={false}
            pauseOnHover={true}
            speed={40}
            style={{ height: '50px' }}
          >
            <Content dangerouslySetInnerHTML={{ __html: category.content }} />
          </Marquee>
        ) : null;
      }}
    </Query>
  );
};

export default InfoText;
