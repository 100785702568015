import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import { TrendButton } from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import React from 'react';
import { Container } from '.';
import FavouriteItem from '../ProductList/FavouriteItem';
import CartHeader from './CartHeader';

const ClearFavorites = styled(TrendButton)`
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 10px;
  width: calc(100% - 20px);
  text-transform: uppercase;
`;

const EmptyFavorites = styled(`div`)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;
`;

const FavouritesPage = ({ setActivePage, activePage, onClose, cart }) => {
  const { products } = useProductListItems();
  const { clear } = useProductList();

  const itemsInCart = cart?.data?.cart?.totalQuantity || 0;
  const emptyFavorites = products?.length === 0;

  return (
    <>
      <CartHeader
        activePage={activePage}
        setActivePage={setActivePage}
        onClose={onClose}
        itemsInCart={itemsInCart}
      >
        <h2>{t('Favourites')}</h2>
      </CartHeader>

      <Container>
        {products?.map(product => (
          <FavouriteItem product={product} onClose={onClose} />
        ))}

        {emptyFavorites && (
          <EmptyFavorites>
            <p>{t('You have no favourites...')}</p>
          </EmptyFavorites>
        )}
      </Container>

      {!emptyFavorites && (
        <ClearFavorites onClick={clear}>{t('Clear favourites')}</ClearFavorites>
      )}
    </>
  );
};

export default FavouritesPage;
