import CartProvider from '@jetshop/core/components/Query/CartProvider';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import React from 'react';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import CartPage from './CartPage';
import FavouritesPage from './FavouritesPage';
import ProfilePage from './ProfilePage';
import cartQuery from './queries/CartQuery.gql';

export const Container = styled('div')`
  padding: 0 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CART_PAGES = {
  CART: 'cart',
  PROFILE: 'profile',
  FAVOURITES: 'favourites'
};

const getCartPage = activePage => {
  switch (activePage) {
    case CART_PAGES.CART:
      return CartPage;

    case CART_PAGES.PROFILE:
      return ProfilePage;

    case CART_PAGES.FAVOURITES:
      return FavouritesPage;

    default:
      return CartPage;
  }
};

const Cart = props => {
  const { width } = useWindowWidth();

  const Page = getCartPage(props.activeCartPage);

  return (
    <CartProvider query={cartQuery}>
      {cart =>
        cart.data && cart.data.cart ? (
          <DrawerTarget id="cart-drawer">
            {() => (
              <Drawer
                isOpen={props.isOpen}
                right
                size={width > 900 ? 500 : width}
              >
                <Page
                  {...props}
                  activePage={props.activeCartPage}
                  setActivePage={props.setActiveCartPage}
                  onClose={() => props.setIsOpen(false)}
                  cart={cart}
                />
              </Drawer>
            )}
          </DrawerTarget>
        ) : null
      }
    </CartProvider>
  );
};

export default Cart;
