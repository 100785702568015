import { css } from 'linaria';
import { styled } from 'linaria/react';
import { default as React } from 'react';
import { ReactComponent as Times } from '../../../svg/Times.svg';
import Cart from '../../Cart';
import MaxWidth from '../MaxWidth';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { SearchClose, SearchWrapper, StyledSearchField } from './Header';
import SearchButton from './SearchButton';
import MenuContainer from './ThreeTierMenu/MenuContainer';
import CartActions from '../../Cart/CartActions';

const navStyle = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  max-width: 72.5rem;
  border-bottom: 1px solid #eaeaea;
`;

const Wrapper = styled(MaxWidth)`
  padding: 0;
  background: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
  max-width: 100%;
  height: 38px;
  max-height: 38px;
`;

function MainMenu({
  setSearchOpen,
  categories,
  searchIsOpen,
  isCartOpen,
  setIsCartOpen,
  activeCartPage,
  setActiveCartPage
}) {
  return (
    <>
      <Wrapper>
        <nav className={navStyle}>
          {searchIsOpen ? (
            <SearchWrapper>
              <StyledSearchField
                onCancel={() => setSearchOpen(false)}
                autocompleteQuery={AutocompleteQuery}
              />
              <SearchClose onClick={() => setSearchOpen(false)}>
                <Times />
              </SearchClose>
            </SearchWrapper>
          ) : (
            <SearchButton setSearchOpen={() => setSearchOpen(true)} />
          )}

          <MenuContainer queryData={categories.data} />

          <CartActions
            setActivePage={setActiveCartPage}
            setIsOpen={setIsCartOpen}
          />
        </nav>
      </Wrapper>
      <Cart
        isOpen={isCartOpen}
        setIsOpen={setIsCartOpen}
        activeCartPage={activeCartPage}
        setActiveCartPage={setActiveCartPage}
      />
    </>
  );
}

export default MainMenu;
