import CategoryLink from '@jetshop/ui/CategoryLink';
import { styled } from 'linaria/react';
import React from 'react';
import posed from 'react-pose';
import { theme } from '../../../Theme';
import SubMenuSection from './SubMenuSection';

// Limit the menu to our global MaxWidth
const MaxWidth = styled('div')``;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')``;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  border: none;
  width: 0;
  padding: 0;
  display: block;
  width: 100%;
`;

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: 0
    }
  })
)`
  height: 0;
  display: none;
  justify-content: center;
  position: absolute;
  left: 0;
  top: calc(100% - 3px);
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 10;
  display: block;
  min-width: 170px;
  left: 10px;
  background: none;
  z-index: 999;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    padding: 0 1rem;
  }
`;

const NoThirdTierItems = styled('div')`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  h2 {
    padding: 0;
    margin: 0;
    width: 100%;
    a {
      background: white;
      color: black;
      text-decoration: none;
      font-weight: normal;
      padding: 15px;
      font-size: 14px;
      width: 100%;
      margin: 0;
      &:hover,
      &.active {
        background: #eaeaea;
        border-color: #eaeaea;
      }
    }
  }
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <PosedWrapper pose={pose}>
      <InnerWrapper>
        <ContentFit>
          <MaxWidth>
            {activeCategory &&
              activeCategory.subcategories.map(cat =>
                cat.subcategories.length > 0 ? (
                  <SubMenuSection
                    key={cat.id}
                    heading={cat}
                    categories={cat.subcategories}
                    onSelect={closeNav}
                  />
                ) : (
                  <NoThirdTierItems key={cat.id}>
                    <h2>
                      <CategoryLink category={cat} onClick={closeNav}>
                        {cat.name}
                      </CategoryLink>
                    </h2>
                  </NoThirdTierItems>
                )
              )}
          </MaxWidth>
        </ContentFit>
      </InnerWrapper>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
