import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';

const Wrapper = styled('div')`
  position: relative;
  width: 80%;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  position: relative;
  overflow: visible;
  a {
    border-bottom: 1px solid #eaeaea;
    color: #000;

    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin: 0 8px -1px 8px;
    padding: 8px 8px;
    text-decoration: none;
    transition: all 0.3s;
    display: block;
    &.active {
      border-color: #000;
    }
    :hover {
      border-color: #000;
    }
  }

  &:hover {
    > section {
      display: block;
      height: auto !important;
      top: 100%;
      margin-top: 2px;
    }
  }
`;

const MenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => (
                  <MainMenuItem
                    key={cat.id}
                    // When mousing over a menu item, set it as the active nav
                  >
                    <CategoryLink
                      // When following a category link, close the menu
                      onClick={props.clearActiveCategories}
                      category={cat}
                    >
                      {cat.id === 446 ? (
                        <span>
                          <em style={{ fontFamily: 'Heldane Italic' }}>the</em>{' '}
                          Club
                        </span>
                      ) : (
                        cat.name
                      )}
                    </CategoryLink>
                    <SubMenuWrapper
                      pose={
                        props.activeCategories.length > 0 &&
                        props.activeCategories[0].subcategories.length > 0
                          ? 'open'
                          : 'closed'
                      }
                      activeCategory={cat}
                      closeNav={props.clearActiveCategories}
                    />
                  </MainMenuItem>
                ))
              : null}
          </ul>
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

export default MenuContainer;
