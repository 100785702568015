/* eslint-disable */
import { css } from 'linaria';
import '@jetshop/ui/Theme/sanitizeCss';
import { theme } from './components/Theme';

const fallbackStyles = `
line-height: 1.4;
font-weight: 400;
letter-spacing: 0px;
word-spacing: 0px;
font-family: "Heldane Regular";
`;

const globals = `
html, body {
    height: 100%;
    font-family: 'Heldane Regular';
  }
  html, body, #root {
    min-height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  button {
	  cursor: pointer;
    padding: 0px;
    border: none;
    font: inherit;
    color: #000000;
	}
  p {
    font-size: 16px;
  }
  ol, ul, h4, h3, h2, h1 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p, dd {
    margin: 0;
    padding: 0;
  }
  input {
    -webkit-appearance: none;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Overlay--after-open {
    overflow: scroll;
  }
  body {
    ${fallbackStyles};
    -webkit-font-smoothing: antialiased;
  }
  /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  iframe#launcher-frame, button.needsclick {
    z-index: 99 !important;
  }

  body.prevent-overflow {
    overflow-y: hidden;
  }
`;

css`
  :global() {
    ${globals};
  }
`;
