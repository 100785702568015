import Accordion from '@jetshop/ui/Accordion/Accordion';
import Link from '@jetshop/ui/CategoryLink';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import React, { Component } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

const CategoryTree = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;
  a {
    background: #f8f8f8;

    font-size: 14px;
    font-weight: 300;
    padding: 1rem 1rem;
    margin: 0.25rem 1rem;
    text-decoration: none;
  }
  .show-all {
    background: #fff;
    display: block;
    margin: 0.5rem 2rem 0rem 2rem;
    width: 100%;
  }

  &.root {
    padding-top: 0.25rem;
  }
`;
const SubCategories = styled(CategoryTree)`
  padding-left: 1rem;
  background-color: #fff;
  font-color: silver;
  a {
    background-color: #fff;
  }
`;

const CategoryLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 1em 1em 0.9375em;
  ${StyledCarrot} {
    display: none;
    margin-right: 0px;
  }

  &.with-chevron {
    ${StyledCarrot} {
      display: block;
    }
    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  position: relative;

  ul li &.active,
  ul li ul li &.active {
    font-weight: bold;
  }

  ul li:last-child &,
  ul li ul li:last-child & {
    border-bottom: none;
  }
`;

const CategoryName = styled('h2')`
  background: #fff;
  padding: 0;
  margin: 0;
  line-height: 0;
  a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const SubCategory = styled('li')`
  font-size: 0.875rem;
`;

const RootCategory = styled('li')`
  background: #fff;
  display: flex;
  flex-direction: column;
`;

export default class DrawerMenu extends Component {
  renderSubCategories = categories => {
    if (!categories || !categories.length) return null;
    const { closeMenu } = this.props;
    return (
      <SubCategories>
        {categories.map(cat => {
          return (
            <SubCategory key={cat.id}>
              <CategoryLink category={cat} onClick={() => closeMenu()} />
            </SubCategory>
          );
        })}
      </SubCategories>
    );
  };

  handleClick = (e, hasChildren, index, handleAccordionClick) => {
    // If there are subcategories, prevent following the link
    const { closeMenu } = this.props;
    if (hasChildren) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      closeMenu();
    }
    handleAccordionClick(index);
  };

  render() {
    const { categories } = this.props;

    if (categories && categories.length > 0) {
      return (
        <CategoryTree root>
          <Accordion single>
            {({ openIndexes, handleClick, AccordionContent }) =>
              categories.map((cat, index) => {
                let classNames = [];
                // Give categories with children a chevron
                cat.subcategories.length && classNames.push('with-chevron');
                // Add 'open' class when accordion is opened
                const open = openIndexes.includes(index);
                open && classNames.push('open');

                return (
                  <RootCategory key={cat.id}>
                    <CategoryName>
                      <CategoryLink
                        category={cat}
                        onClick={e =>
                          this.handleClick(
                            e,
                            cat.subcategories.length,
                            index,
                            handleClick
                          )
                        }
                        className={
                          classNames.length > 0 && classNames.join(' ')
                        }
                      >
                        {cat.id === 446 ? (
                          <span>
                            <em style={{ fontFamily: 'Heldane Italic' }}>
                              the
                            </em>{' '}
                            Club
                          </span>
                        ) : (
                          cat.name
                        )}
                        <StyledCarrot isOpen={open} />
                      </CategoryLink>
                    </CategoryName>
                    {cat.hasSubcategories && (
                      <AccordionContent isOpen={open}>
                        <Link
                          category={cat}
                          onClick={this.props.closeMenu}
                          className="show-all"
                        >
                          {t('Show all in')} {cat.name}
                        </Link>

                        {this.renderSubCategories(cat.subcategories)}
                      </AccordionContent>
                    )}
                  </RootCategory>
                );
              })
            }
          </Accordion>
        </CategoryTree>
      );
    } else return null;
  }
}
