import { useQuery } from '@apollo/react-hooks';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import { TrendButton } from '@jetshop/ui/Button';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '.';
import { useKlaviyoTracking } from '../../hooks/useKlaviyoTracking';
import { ReactComponent as Box } from '../../svg/Box.svg';
import ProductsCarousel from '../ProductsCarousel';
import { useYotpoLoyalty } from '../Yotpo/YotpoLoyalty';
import CartHeader from './CartHeader';
import CartItem from './CartItem';
import ProductsByCategory from './queries/ProductsByCategory.gql';
import getStandardShippingCost from './utils/getStandardShippingCost';
import ProductSlider from '../ProductSlider';
import { Above } from '@jetshop/ui/Breakpoints';

const StyledTrendButton = styled(TrendButton)`
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const ShippingStatus = styled(`div`)`
  margin-top: 10px;

  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.bold {
      font-weight: bold;
    }
  }

  svg {
    margin-left: 5px;
  }

  [data-flight-price],
  .price {
    display: inline;
    margin: 0 3px;
  }
`;

const Summary = styled('div')`
  padding: 10px;
  border-top: 1px solid #eaeaea;

  .cart-total {
    .shipping,
    .total,
    .discounts,
    .vat,
    .order-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .vat {
      color: #aaa;
    }

    .order-total {
      font-weight: bold;
      font-size: 1.15rem;
      text-transform: uppercase;

      > span {
        text-transform: initial;
      }
    }
  }
`;

const RewardInfo = styled(`div`)`
  text-align: center;
  padding: 0 10px 10px 10px;

  a {
    color: #000;
    text-decoration: underline !important;
    text-transform: uppercase;
  }
`;

const EmptyCart = styled(`div`)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;

  > div {
    margin-bottom: 20px;
  }

  a {
    margin: 0 5px;
    color: #000;
    text-decoration: underline !important;
  }

  a[href='/signup'] {
    text-transform: uppercase;
  }
`;

const Checkout = styled(TrendButton)`
  background: #000;
  color: #fff !important;
  height: 54px;
  line-height: 54px;
  padding: 1em;
  text-align: center;
  transition: all ease 0.3s;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
  margin-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  -webkit-appearance: none;

  :hover {
    background: #333;
  }
  :active,
  :focus {
    outline: none;
  }
`;

const Error = styled('div')`
  color: #eb5757;
  font-size: 0.875rem;
  margin: 0 auto;
  margin-bottom: 10px;
`;

const AnythingElse = styled(`div`)`
  margin-top: auto;

  h3 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .category-colors {
    display: none;
  }

  [data-flight-price] {
    flex-direction: column;
  }
`;

const CartPage = ({ cart, setActivePage, activePage, onClose }) => {
  const items = cart?.data?.cart?.items || [];
  const checkoutUrl = cart?.data?.cart?.externalCheckoutUrl;
  const track = useTracker();
  const { selectedChannel } = useContext(ChannelContext);
  const { startedCheckout } = useKlaviyoTracking();

  const useLoyalty = useYotpoLoyalty();

  const hideShippingInformation = selectedChannel.id === 3;

  const { data: anythinglseProducts } = useQuery(ProductsByCategory, {
    variables: {
      id: 435
    }
  });

  const { data: bestSellersProducts } = useQuery(ProductsByCategory, {
    variables: {
      id: 370
    }
  });

  const anythinglseProductsGrid =
    anythinglseProducts?.category?.products?.result;
  const bestSellersProductsGrid =
    bestSellersProducts?.category?.products?.result;

  const VAT = new Intl.NumberFormat(selectedChannel.defaultLanguage.culture, {
    style: 'currency',
    currency: selectedChannel.currency.name
  }).format(cart.data.cart.productTotal.vat);

  const shippingCost = getStandardShippingCost(selectedChannel.id);

  const formattedShippingCost = new Intl.NumberFormat(
    selectedChannel.defaultLanguage.culture,
    {
      style: 'currency',
      currency: selectedChannel.currency.name
    }
  ).format(shippingCost);

  const emptyCart = items.length === 0;
  const itemsInCart = cart?.data?.cart?.totalQuantity || 0;

  const hasOutOfStockItems = items?.some(
    item => !item?.product?.stockStatus?.buyable
  );

  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal: cart.data.cart.productTotal
  });

  const orderTotal =
    cart.data.cart.productTotal.incVat + (hasMetLimit ? 0 : shippingCost);

  // All countries except SE and NO get price * 10 in points.
  const pointsOnPurchase = [1, 4].includes(selectedChannel.id)
    ? cart.data.cart.productTotal.incVat
    : cart.data.cart.productTotal.incVat * 10;

  const formattedOrderTotal = new Intl.NumberFormat(
    selectedChannel.defaultLanguage.culture,
    {
      style: 'currency',
      currency: selectedChannel.currency.name
    }
  ).format(orderTotal);

  return (
    <>
      <CartHeader
        setActivePage={setActivePage}
        activePage={activePage}
        onClose={onClose}
        itemsInCart={itemsInCart}
      >
        <h2>{t('Your cart')}</h2>
      </CartHeader>

      <Container>
        {emptyCart && (
          <EmptyCart>
            <div>{t('No items in cart.')}</div>

            <StyledTrendButton onClick={onClose}>
              {t('Continue shopping')}
            </StyledTrendButton>

            {useLoyalty && (
              <div>
                {t.rich('Psst! {signup} and {rewards}', {
                  signup: (
                    <Link to="/signup" onClick={onClose}>
                      {t('Join the club')}
                    </Link>
                  ),
                  rewards: (
                    <Link to="/the-club" onClick={onClose}>
                      {t('Collect points')}
                    </Link>
                  )
                })}
              </div>
            )}
          </EmptyCart>
        )}

        {items.map(item => (
          <CartItem item={item} key={item.id} onClose={onClose} />
        ))}

        {!hideShippingInformation && !emptyCart && freeShippingConfigured && (
          <ShippingStatus>
            {hasMetLimit ? (
              <span className="bold">
                {t('Free shipping')} <Box />
              </span>
            ) : (
              <span>
                {t.rich('{amount} left until free shipping', {
                  amount: (
                    <span className="bold">
                      <Price price={untilLimit} />
                    </span>
                  )
                })}{' '}
                <Box />
              </span>
            )}
          </ShippingStatus>
        )}

        {!emptyCart && anythinglseProductsGrid?.length > 0 && (
          <AnythingElse>
            <h3>{t('Do you need anything else?')}</h3>
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <ProductsCarousel
                    autoPlay={false}
                    productsToShow={3}
                    products={anythinglseProductsGrid}
                    onClick={onClose}
                  />
                ) : (
                  <ProductSlider
                    products={anythinglseProductsGrid}
                    onClick={onClose}
                  />
                )
              }
            </Above>
          </AnythingElse>
        )}

        {emptyCart && bestSellersProductsGrid?.length > 0 && (
          <AnythingElse>
            <h3>{t('Our best sellers')}</h3>
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <ProductsCarousel
                    autoPlay={false}
                    productsToShow={3}
                    products={bestSellersProductsGrid}
                    onClick={onClose}
                  />
                ) : (
                  <ProductSlider
                    products={bestSellersProductsGrid}
                    onClick={onClose}
                  />
                )
              }
            </Above>
          </AnythingElse>
        )}
      </Container>

      {!emptyCart && (
        <>
          <Summary>
            <div className="cart-total">
              <div className="total">
                {t('Subtotal')}
                <Price price={cart.data.cart.productPreviousTotal} />
              </div>
              <div className="vat">
                {t('Varav moms')}
                <span>{VAT}</span>
              </div>

              <div className="discounts">
                {t('Discounts')}
                <Price price={cart.data.cart.discountTotal} />
              </div>

              {!hideShippingInformation && (
                <div className="shipping">
                  {t('Shipping')}
                  <span>
                    {hasMetLimit || shippingCost === 0
                      ? t('Free')
                      : formattedShippingCost}
                  </span>
                </div>
              )}

              <div className="order-total">
                {t('Total')}
                <span>{formattedOrderTotal}</span>
              </div>
            </div>
          </Summary>

          {hasOutOfStockItems && (
            <Error>{t('One or more items is out of stock')}</Error>
          )}

          {checkoutUrl && (
            <Checkout
              disabled={hasOutOfStockItems}
              anchor={true}
              href={checkoutUrl}
              onClick={event => {
                event.preventDefault();

                if (hasOutOfStockItems) return;

                startedCheckout(cart.data.cart);
                track(
                  trackCartCheckoutEvent({
                    cart: cart.data.cart,
                    callback: () => {
                      window.location = checkoutUrl;
                    }
                  })
                );
              }}
            >
              {t('Check out')}
            </Checkout>
          )}

          {useLoyalty && (
            <RewardInfo>
              {t.rich('Points on this purchase: {points}', {
                points: <b>{Math.trunc(pointsOnPurchase)}</b>
              })}

              <br />

              {t.rich('Not a member? {link}', {
                link: (
                  <Link to="/signup" onClick={onClose}>
                    {t('Join the club')}
                  </Link>
                )
              })}
            </RewardInfo>
          )}
        </>
      )}
    </>
  );
};

export default CartPage;
