import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';
import { styled } from 'linaria/react';

const IconContainer = styled('div')`
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  button {
    background: transparent;
    outline: none;
  }
  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: #000;
    }

    &.active {
      fill: #000;
    }
  }
  &.active {
    &::before {
      pointer-events: none;
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
      box-shadow: inset 0 0 2px 2px #000;
      width: 34px;
      height: 34px;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0);
      border-radius: 50%;
      opacity: 0;
    }
  }
`;

export function AddFavourite({ product, variant, ...rest }) {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList();

  function toggleFavourite(e) {
    e.preventDefault();
    e.stopPropagation();
    toggle(articleNumber, {
      variantArticleNumber
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <IconContainer
      onClick={toggleFavourite}
      className={isInList ? 'active' : 'inactive'}
      {...rest}
    >
      <button>
        <HeartSVG className={isInList ? 'active' : 'inactive'} />
      </button>
    </IconContainer>
  );
}
