import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useContext } from 'react';
import { useScript } from '../../hooks/useScript';

const moduleId = {
  // SE
  1: 'IgEyJcC1nOzrzq5SeDny5A',
  // DK
  2: '',
  // EU
  3: 'InANtaHs9q-81kEmSWayyg',
  // NO
  4: 'ZdEeWChwfrD_D9hcGhfUHw',
  // DE
  5: 'ITDwl0wLWuQ_nO8x295ANw',
  // US
  7: '7FSIPU0aOZOvBAX8az4kDw',
  // UK
  8: '',
  // FR
  9: ''
};

const instanceId = {
  // SE
  1: '365178',
  // DK
  2: '',
  // EU
  3: '385277',
  // NO
  4: '385118',
  // DE
  5: '384567',
  // US
  7: '385232',
  // UK
  8: '',
  // FR
  9: ''
};

export const useYotpoModuleId = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return moduleId[selectedChannel.id];
};

export const useYotpoInstanceId = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return instanceId[selectedChannel.id];
};

export const useYotpoLoyalty = () => {
  const instanceId = useYotpoInstanceId();
  const moduleId = useYotpoModuleId();

  return Boolean(instanceId && moduleId);
};

export const useYotpoLoyaltyWidget = () => {
  const yotpoModuleId = useYotpoModuleId();
  const scriptSrc = `https://cdn-widgetsrepository.yotpo.com/v1/loader/${yotpoModuleId}`;

  useScript(scriptSrc);
};
