import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import cartQuery from './queries/CartQuery.gql';
import decrementQuantityMutation from './queries/decrementQuantity.gql';
import incrementQuantityMutation from './queries/incrementQuantity.gql';
import removeFromCartMutation from './queries/removeFromCart.gql';

export const Wrapper = styled('div')`
position: relative;
  display: flex;
  background: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 0.5rem 0;
  
  > :first-child {
    width: 125px
    margin-right: 10px;
  }
  > :last-child {
    width: 100%;
  }
`;

export const ProductName = styled('div')`
  color: #000;
  font-size: 14px;
  padding-right: 20px;

  font-weight: normal;
  a {
    color: black;
    text-decoration: none;
  }
`;

export const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: auto 100px;
  grid-template-areas: 'topleft topleft' 'bottomleft bottomright';
`;

export const ItemDetails = styled('div')`
  grid-area: topleft;
`;

export const OutOfStock = styled(`div`)`
  color: #c60f12;
  font-weight: 600;
`;

export const LinePrice = styled(Price)`
  .new-price {
    color: #c60f12;
    font-weight: 600;
  }
  .old-price {
    color: #aaa;
  }
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: transparent;
    color: #000;
    font-size: 16px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
  }
`;

export const RemoveItem = styled('div')`
  position: absolute;
  top: 10px;
  right: 0;
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: #ccc;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.3s;
    :hover {
      background: #000;
    }
  }
  svg {
    height: 8px;
    width: 8px;
  }
`;

const ColorDot = styled('span')`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
`;

export const PriceWrapper = styled('div')`
  margin-top: 1rem;
  grid-area: bottomright;
  text-align: right;
`;

const CartItem = ({ item, className = '', onClose }) => {
  const { variantImage, variantValues, hasVariantImage } = getCartItemVariant(
    item
  );

  const outOfStock = !item?.product?.stockStatus?.buyable;

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  return (
    <Wrapper className={className}>
      <div>
        {item.product.images.length > 0 && (
          <Image
            aspect="2:3"
            sizes="200px"
            src={
              hasVariantImage ? variantImage.url : item.product.images[0].url
            }
            alt={
              hasVariantImage ? variantImage.alt : item.product.images[0].alt
            }
            quality={80}
          />
        )}
      </div>
      <ProductDetail>
        <ItemDetails>
          <ProductName>
            <Link to={item.product.primaryRoute.path} onClick={onClose}>
              <h2
                style={{
                  fontSize: '16px',
                  fontWeight: '400'
                }}
              >
                {item.product.name}
              </h2>
            </Link>
            <LinePrice
              price={item.product.price}
              previousPrice={item.product.previousPrice}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {variantValues[0]}{' '}
              <ColorDot style={{ backgroundColor: 'black' }} />{' '}
              {item.subHeading}
            </div>
          </ProductName>
        </ItemDetails>

        <RemoveItem>
          <button
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          >
            <Cross />
          </button>
        </RemoveItem>

        {!outOfStock && (
          <AdjustQty>
            <button
              disabled={item.quantity === 1}
              onClick={() =>
                item.quantity !== 1 && decrementQuantity({ itemId: item.id })
              }
            >
              -
            </button>
            <span>{item.quantity}</span>
            <button onClick={() => incrementQuantity({ itemId: item.id })}>
              +
            </button>
          </AdjustQty>
        )}

        <PriceWrapper>
          {outOfStock ? (
            <OutOfStock>{t('Out of stock')}</OutOfStock>
          ) : (
            <LinePrice price={item.total} previousPrice={item.previousTotal} />
          )}
        </PriceWrapper>
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
