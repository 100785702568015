import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../Theme';
import MaxWidth from '../MaxWidth';
import FooterLinks from './FooterLinks';
import NewsletterField from './Newsletter/NewsletterField';
import SocialLinks from './SocialLinks';
import StoreContact from './StoreContact';

const backgroundColor = '#eaeaea';


const Wrapper = styled('section')`
  text-align: left;
  font-size: 0.875rem;
  background: ${backgroundColor};
  padding-top: 0.5rem;
  ${theme.above.md} {
    padding-top: 2rem;
  }

  + div {
    z-index: 99;
  }

  h2 {
    color: black;

    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.4;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    ${theme.below.md} {
      margin-top: 2rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: black;

    font-weight: 300;
    line-height: 0.5rem;
  }
  li {
    padding: 0.5rem 0;
  }
  a {
    color: black;
    text-decoration: none;
    transition: all 0.3s;
    :hover {
      color: #000;
      transition: all 0.3s linear;
    }
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0;
  max-width: 72rem;

  ${theme.below.lg} {
    padding: 0rem 1rem;
  }

  ${theme.below.md} {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  > section {
    margin: 0;
    flex: 0 1 25%;
  }
`;

const WrapperRight = styled('section')`
  ${theme.above.md} {
    text-align: right;
    margin-top: 0;
    width: 288px;
  }
`;

const Footer = () => (
  <Wrapper>
    <WrapFooterNav>
      <FooterLinks />

      <StoreContact />

      <WrapperRight>
        <section>
          <NewsletterField />
          <SocialLinks />
        </section>
      </WrapperRight>
    </WrapFooterNav>

  </Wrapper>
);

export default Footer;
