import React from 'react';
import { styled } from 'linaria/react';
import { useYotpoWidget } from './Yotpo';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
  display: block;
  position: relative;
  margin: auto;
`;

export const YotpoUGC = ({ galleryId, productId }) => {
  const loaded = useYotpoWidget();

  return (
    <>
      <div className="yotpo-reviews" />
      {loaded ? (
        <Wrapper>
          {productId ? (
            <div
              className="yotpo yotpo-pictures-widget"
              data-gallery-id={galleryId || '633ebeb36cfedf001b09de86'}
              data-product-id={productId || ''}
            ></div>
          ) : (
            <div
              className="yotpo yotpo-pictures-widget"
              data-gallery-id={galleryId || '633ebeb36cfedf001b09de86'}
            ></div>
          )}
        </Wrapper>
      ) : (
        ''
      )}
    </>
  );
};

export default YotpoUGC;
