import { useEffect } from 'react';

export const useKlaviyoTracking = () => {
  let klaviyo;

  if (typeof window !== 'undefined' && window?._learnq) {
    klaviyo = window?._learnq;
  } else {
    console.warn('Klaviyo not found');
  }

  const viewedProduct = product => {
    if (klaviyo && product) {
      const item = {
        ProductName: product?.name,
        ProductID: product?.id,
        SKU: product?.articleNumber,
        Categories: product?.categories?.map(category => category?.name),
        ImageURL: product?.images?.[0]?.url,
        URL: window?.location?.origin + product?.primaryRoute?.path,
        // Brand: 'Kids Books',
        Price: product?.price?.incVat
        // CompareAtPrice: 14.99
      };
      klaviyo.push(['track', 'Viewed Product', item]);
    } else {
      console.warn('Klaviyo, product or both are missing for Viewed Product');
    }
  };

  const startedCheckout = cart => {
    if (klaviyo && cart) {
      const item = {
        $event_id: `${cart.id}_${Math.floor(Date.now() / 1000)}`,
        $value: cart?.productTotal?.incVat,
        ItemNames: cart?.items?.map(item => item?.product?.name),
        CheckoutURL: cart?.externalCheckoutUrl,
        Categories: [
          ...new Set(
            cart?.items?.flatMap(item =>
              item?.product?.categories?.flatMap(category => category?.name)
            )
          )
        ],
        Items: cart?.items?.flatMap(item => ({
          ProductID: item?.product?.id,
          SKU: item?.product?.articleNumber,
          ProductName: item?.product?.name,
          Quantity: item?.quantity,
          ItemPrice: item?.product?.price?.incVat,
          RowTotal: item?.total?.incVat,
          ProductURL:
            window.location.origin + item?.product?.primaryRoute?.path,
          ImageURL: item?.product?.images[0].url,
          ProductCategories: [
            ...new Set(
              item?.product?.categories?.flatMap(category => category?.name)
            )
          ]
        }))
      };
      klaviyo.push(['track', 'Started Checkout', item]);
    } else {
      console.warn('Klaviyo, cart or both are missing for Started Checkout');
    }
  };

  const addedToCart = (product, quantity, cart) => {
    if (klaviyo && product && cart) {
      const item = {
        $value: cart?.productTotal?.incVat,
        AddedItemProductName: product?.name,
        AddedItemProductID: product?.id,
        AddedItemSKU: product?.articleNumber,
        AddedItemCategories: product?.categories?.map(
          category => category?.name
        ),
        AddedItemImageURL: product?.images?.[0]?.url,
        AddedItemURL: window?.location?.origin + product?.primaryRoute?.path,
        AddedItemPrice: product?.price?.incVat,
        AddedItemQuantity: quantity,
        ItemNames: cart?.items?.map(item => item?.product?.name),
        CheckoutURL: cart?.externalCheckoutUrl,
        Items: cart?.items?.flatMap(item => ({
          ProductID: item?.product?.id,
          SKU: item?.product?.articleNumber,
          ProductName: item?.product?.name,
          Quantity: item?.quantity,
          ItemPrice: item?.product?.price?.incVat,
          RowTotal: item?.total?.incVat,
          ProductURL:
            window.location.origin + item?.product?.primaryRoute?.path,
          ImageURL: item?.product?.images[0].url,
          ProductCategories: [
            ...new Set(
              item?.product?.categories?.flatMap(category => category?.name)
            )
          ]
        }))
      };

      klaviyo.push(['track', 'Added to Cart', item]);
    } else {
      console.warn('Klaviyo, product, cart or all are missing');
    }
  };

  return { viewedProduct, startedCheckout, addedToCart };
};
