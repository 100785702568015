import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { AddFavourite } from '../ProductList/AddFavourite';
import ProductColors from '../ProductPage/ProductColors/ProductColors';
import { theme } from '../Theme';
import { ProductGridRatings } from '../Yotpo/ProductGridRatings';

const StyledPrice = styled(Price)`
  display: flex;
  justify-content: center;
  align-items: center;

  .price {
    font-weight: inherit !important;
    font-size: inherit !important;
    margin-bottom: 0 !important;
  }

  .new-price,
  .price {
    line-height: 1.5em !important;
  }

  .new-price {
    color: #c60f12;
    font-weight: 700;
  }
  .old-price {
    margin-left: 5px;
    color: #999;
    display: block;
    font-size: 14px;
    font-weight: 300;
  }

  &.is-package {
    .new-price {
      color: black !important;
    }
  }
`;

export const ProductWrapper = styled('div')`
  position: relative;
  width: 50%;
  margin-bottom: 36px;
  ${theme.above.md} {
    width: 33.333%;
  }
  ${theme.above.lg} {
    width: 25%;
  }

  .badges-wrapper {
    > div > div > div {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.55rem;
      letter-spacing: 0.5px;
      width: auto;
      height: auto;
      color: black;
      background: none;
      img {
        width: 70%;
        height: auto;
        margin-left: auto;
      }
      ${theme.below.lg} {
        img {
          width: 70%;
          height: auto;
          margin-left: auto;
        }
      }
      ${theme.below.md} {
        img {
          width: 60%;
          height: auto;
          margin-left: auto;
        }
      }

      &.sale-tagg {
        background: #9a2936;
      }

      &.member-tagg {
        background: #b78c1f;
      }

      &.best-price-tagg {
        background: #ed7b2b;
      }

      &.new-tagg {
        background: #000;
      }
    }
  }
`;

export const ProductBlockLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  display: block;
  margin-right: ${theme.space[1]};
  margin-left: ${theme.space[1]};
  border: none;
  background-color: white;

  img {
    transition: transform 4s cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 500ms ease 0s !important;

    &:hover {
      ${theme.above.md} {
        transform: scale(1.1);
      }
    }
  }
`;
export const ProductDetails = styled('div')`
  background-color: #fff;
  line-height: 20px;
  padding: 8px 8px 0 8px;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
`;
export const Name = styled('p')`
  font-size: 14px;
`;

const GridProduct = ({
  product,
  categoryPath,
  loading,
  imageAspect = '2:3',
  className = '',
  onClick
}) => {
  const [activeProduct, setActiveProduct] = useState();

  const productImage = activeProduct?.images?.[0] || product?.images?.[0];

  return (
    <ProductWrapper data-testid="product" className={className}>
      <ProductBlockLink
        onClick={() => onClick?.()}
        product={product}
        categoryPath={categoryPath}
        style={
          loading
            ? {
                opacity: '0'
              }
            : null
        }
      >
        {productImage && (
          <div style={{ position: 'relative' }}>
            <Image
              sizes={[1 / 4, 1 / 3, 1 / 2, 1 / 2]}
              aspect={imageAspect}
              alt={productImage?.alt}
              src={productImage?.url}
            >
              <div className="badges-wrapper">
                <Badges badges={product.badges} />
              </div>
            </Image>

            <AddFavourite
              product={product}
              style={{ bottom: '8px', right: '8px' }}
            />
          </div>
        )}
        <ProductDetails>
          <Name>{product.name}</Name>
          <StyledPrice
            className={product.isPackage ? 'is-package' : ''}
            price={product.price}
            recommendedPrice={product.recommendedPrice}
            previousPrice={product.previousPrice}
          />
        </ProductDetails>
      </ProductBlockLink>
      <ProductGridRatings id={product.id} />
      <ProductColors
        mainProductId={product.id}
        productArtNo={product.articleNumber}
        colorName={product.subName}
        categoryPage={true}
        updateActiveProduct={setActiveProduct}
      />
    </ProductWrapper>
  );
};

export default GridProduct;
