import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { SearchField } from '@jetshop/ui/Search';
import { styled } from 'linaria/react';
import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { ReactComponent as Menu } from '../../../svg/Bars.svg';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { ReactComponent as Times } from '../../../svg/Times.svg';
import Cart, { CART_PAGES } from '../../Cart';
import { theme } from '../../Theme';
import { Notifications } from '../Notifications';
import AutocompleteQuery from './AutocompleteQuery.gql';
import ChannelSelector from './ChannelSelector/ChannelSelector';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import InfoText from './InfoText';
import MainNav from './MainNav';
import MobileMenu from './MobileMenu';
import { cx } from 'linaria';
import CartActions from '../../Cart/CartActions';

const Container = styled('header')`
  display: block;
  width: 72.5rem;
  margin: auto;
  padding: 0;
  margin-bottom: 0;
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: #000;
    }
  }
  ${theme.below.lg} {
    padding: 0 1rem;
    margin-bottom: 0;
    width: 100%;

    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom: 1px solid #eaeaea;
  }
`;

const MobileMenuContainer = styled('div')`
  background: transparent;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
`;

const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  margin-right: 1rem;
  > span {
    margin-top: 5px;
  }
  > svg {
    height: 25px;
    width: 25px;
    ${theme.below.xs} {
      height: 18px;
      width: 18px;
    }
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
`;

const SearchButton = styled('button')`
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  > span {
    font-size: 12px;
    margin-top: 5px;
  }
  > svg {
    height: 25px;
    width: 25px;
    ${theme.below.xs} {
      height: 18px;
      width: 18px;
    }
  }
`;

const Filler = styled('span')`
  width: 135px;
  ${theme.below.md} {
    width: auto;
    flex: 1;
  }
`;

const InfoTextWrapper = styled('div')`
  background: black;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  z-index: 100;
  ${theme.below.md} {
    padding-left: 10px;
    text-align: left;
    flex: 5;
  }
`;

const Channel = styled('span')`
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  text-align: right;
  width: 135px;
  ${theme.below.md} {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: auto;
    flex: 1;
  }
`;

const NavLeft = styled('div')`
  background: #fff;
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const NavRight = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const StoreName = styled('h2')`
  color: inherit;
  text-align: center;
  text-decoration: none;
  margin: 20px auto;
  max-width: 300px;

  svg {
    width: 300px;
    height: 86px;
  }

  ${theme.below.lg} {
    flex: 3;
    margin: 0;
    svg {
      width: 150px;
      height: 43px;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  img {
    margin: 1.5rem 0rem;
    max-width: 250px;
  }
  ${theme.below.lg} {
    img {
      margin: 0;
      max-width: 150px;
    }
  }
`;

export const StyledSearchField = styled(SearchField)`
  ${theme.above.lg} {
    width: 100%;
  }
  ${theme.below.lg} {
    flex: 2;
    margin: 18.5px 0 12.5px 0;
  }

  ${SearchField.Wrapper} {
    width: 100%;
    display: flex;
    height: 1.5rem;

    ${theme.below.lg} {
      width: 50%;
      height: 2rem;
    }
    ${theme.below.sm} {
      width: 100%;
    }
    input {
      border: 0;
      background: #f3f3f3;
      height: 100%;
      flex: 1 1 auto;
      padding: 0 1rem;
      display: flex;
      align-items: bottom;
      justify-content: flex-start;
      outline-color: #999;
      ${theme.below.sm} {
        width: 100%;
        font-size: 16px;
      }
    }
    button {
      color: #000;
      outline: none;

      font-size: 15px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }
  ${SearchField.Cancel} {
    display: none;
  }
  ${SearchField.Flyout} {
    text-align: left;
    ${theme.below.sm} {
      top: 2.8rem;
    }
    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      padding: 0.5rem;
      border-top: 1px solid #e8e8e8;
    }
    a {
      display: block;
      color: #000;
      text-decoration: none;
    }
  }
`;

const MenuLabel = styled('span')`
  text-transform: uppercase;
  ${theme.below.md} {
    display: none;
  }
`;

const SearchLabel = styled('span')`
  text-transform: uppercase;
  ${theme.below.md} {
    display: none;
  }
`;
export const SearchWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${theme.above.lg} {
    width: 30%;
  }
`;
export const SearchClose = styled('div')`
  padding: 0 10px;
  svg {
    width: 15px;
    height: 25px;
  }
`;

const SidebarOverlay = styled('div')`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
  transition .2s ease-in-out opacity;
  pointer-events: none;
  opacity: 0;
  cursor: pointer;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
`;

function Header(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [activeCartPage, setActiveCartPage] = useState(CART_PAGES.CART);

  useEffect(() => {
    if (mobileMenuOpen) {
      setCartOpen(false);
    }

    if (cartOpen) {
      setMobileMenuOpen(false);
    }

    if (mobileMenuOpen || cartOpen) {
      document.body.classList.add('prevent-overflow');
    } else if (!mobileMenuOpen && !cartOpen) {
      document.body.classList.remove('prevent-overflow');
    }
  }, [mobileMenuOpen, cartOpen]);

  return (
    <>
      <SidebarOverlay
        onClick={() => {
          setCartOpen(false);
          setMobileMenuOpen(false);
        }}
        className={cx((mobileMenuOpen || cartOpen) && 'active')}
      />
      <InfoTextWrapper>
        <Filler>
          <span>&nbsp;</span>
        </Filler>

        <InfoText />

        <Channel>
          <ChannelSelector />
        </Channel>
      </InfoTextWrapper>

      <Container>
        <Above breakpoint="lg">
          <>
            <StoreName>
              <Link to="/">
                {/* <img src={logo} alt={'Bread and Boxers logo'} /> */}
                <Logo />
              </Link>
            </StoreName>
          </>
        </Above>

        <Below breakpoint="lg">
          <>
            <nav>
              {searchOpen ? (
                <SearchWrapper>
                  <StyledSearchField
                    onCancel={() => setSearchOpen(false)}
                    autocompleteQuery={AutocompleteQuery}
                  />
                  <SearchClose onClick={() => setSearchOpen(false)}>
                    <Times />
                  </SearchClose>
                </SearchWrapper>
              ) : (
                <>
                  <MobileMenuContainer>
                    <NavLeft>
                      <DrawerTrigger preventOverflow={true} id="menu-drawer">
                        {() => (
                          <MenuButton
                            onClick={() =>
                              mobileMenuOpen
                                ? setMobileMenuOpen(false)
                                : setMobileMenuOpen(true)
                            }
                          >
                            <Menu />
                            <MenuLabel>{t('Menu')}</MenuLabel>
                          </MenuButton>
                        )}
                      </DrawerTrigger>
                      <SearchButton onClick={() => setSearchOpen(true)}>
                        <Search />
                        <SearchLabel>{t('Search')}</SearchLabel>
                      </SearchButton>
                    </NavLeft>

                    <StoreName>
                      <Link to="/">
                        <Logo />
                      </Link>
                    </StoreName>

                    <NavRight>
                      <CartActions
                        setActivePage={setActiveCartPage}
                        setIsOpen={setCartOpen}
                      />
                    </NavRight>
                  </MobileMenuContainer>
                </>
              )}
              <Cart
                isOpen={cartOpen}
                setIsOpen={setCartOpen}
                activeCartPage={activeCartPage}
                setActiveCartPage={setActiveCartPage}
              />
            </nav>
            <MobileMenu
              setIsOpen={setMobileMenuOpen}
              isOpen={mobileMenuOpen}
              data={props.result.data}
            />
          </>
        </Below>
      </Container>
      <Above breakpoint="lg">
        <MainNav
          setSearchOpen={setSearchOpen}
          searchIsOpen={searchOpen}
          categories={props.result}
          isCartOpen={cartOpen}
          setIsCartOpen={setCartOpen}
          activeCartPage={activeCartPage}
          setActiveCartPage={setActiveCartPage}
        />
      </Above>
    </>
  );
}

const HeaderContainer = () => (
  <Query variables={{ levels: 1 }} query={homeCategoriesQuery}>
    {result => (
      <>
        <Header result={result} />
        <Notifications />
      </>
    )}
  </Query>
);

export default HeaderContainer;
