import { styled } from 'linaria/react';
import GridProduct from '../CategoryPage/GridProduct';
import React from 'react';
import { css, cx } from 'linaria';

const StyledProductSlider = styled.div`
  display: flex;
  overflow: auto;
`;

const ProductStyle = css`
  flex-shrink: 0;
`;

const ProductSlider = ({ products, isLoading, onClick }) => {
  return (
    <StyledProductSlider>
      {products?.map(product => (
        <GridProduct
          key={product.id}
          loading={isLoading}
          product={product}
          onClick={onClick}
          className={cx(ProductStyle)}
        />
      ))}
    </StyledProductSlider>
  );
};

export default ProductSlider;
