import HeldaneMediumEot from './heldane-text-web-medium.eot';
import HeldaneMediumWoff from './heldane-text-web-medium.woff';
import HeldaneMediumWoff2 from './heldane-text-web-medium.woff2';
import HeldaneRegularEot from './heldane-text-web-regular.eot';
import HeldaneRegularWoff from './heldane-text-web-regular.woff';
import HeldaneRegularWoff2 from './heldane-text-web-regular.woff2';
import HeldaneItalicWoff2 from './heldane-text-web-regular-italic.woff2';
import HeldaneItalicWoff from './heldane-text-web-regular-italic.woff';
import HeldaneItalicEot from './heldane-text-web-regular-italic.eot';
import { css } from 'linaria';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    /* Regular font */
    @font-face {
      font-family: 'Heldane Regular';
      src: url(${HeldaneRegularEot});
      src: url(${HeldaneRegularWoff2}) format('woff2'),
        url(${HeldaneRegularWoff}) format('woff');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    /* Bold font */
    @font-face {
      font-family: 'Heldane Medium';
      src: url(${HeldaneMediumEot});
      src: url(${HeldaneMediumWoff2}) format('woff2'),
        url(${HeldaneMediumWoff}) format('woff');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }

    /* Italic font */
    @font-face {
      font-family: 'Heldane Italic';
      src: url(${HeldaneItalicEot});
      src: url(${HeldaneItalicWoff2}) format('woff2'),
        url(${HeldaneItalicWoff}) format('woff');
      font-weight: 400;
      font-style: italic;
      font-display: swap;
    }
  `;
}
