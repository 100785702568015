import t from '@jetshop/intl';
import ChannelSelector, {
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import {
  CancelButton,
  CommitButton
} from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';

import { ReactComponent as Globe } from '../../../../svg/globe.svg';

const Country = styled('div')`
  height: 42px;
  font-size: 14px;
  background-color: ${({ isActive }) => (isActive ? '#f8f8f8' : 'transparent')};
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  color: #000;

  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? '600' : '300')};
  transition: all ease 0.3s;
  :hover {
    background: #f8f8f8;
  }
`;

const LangCurrWrapper = styled('div')`
  background: #f8f8f8;
  margin: 1rem 14px;
  padding: 1rem 0.5rem 0rem 0.5rem;
`;

const SelectorBody = styled('section')``;

const CountryFlag = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: 15px;
`;

const International = styled('div')`
  height: 20px;
  margin-right: 15px;
  background: #fff;
  text-align: center;
  width: 20px;

  svg.svg-inline--fa {
    color: black;
    height: 20px;
    width: 20px;
  }
`;

const ChecboxGroupContainer = styled('div')`
  display: flex;
  flex-direction: column;
  & > div {
    position: relative;
    padding-left: 1rem;
    & > span {
      position: absolute;
      text-align: left;

      font-size: 14px;
      text-transform: uppercase;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 0.6rem;
        color: #000;
        text-transform: none;
      }
      & + label {
        margin-top: 0;
      }
    }
  }
  input ~ span {
    border: 1px solid #bbb;
    svg {
      height: 8px;
    }
  }
  label input:checked ~ span {
    background-color: #bbb;
  }
`;

const CheckboxGropDivider = styled('div')`
  background: inherit;
  color: #000;

  font-size: 14px;
  margin: 0;
  text-align: left;
  label {
    font-size: 12px;
    font-weight: 300;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  span {
    font-weight: 300;
    text-transform: uppercase;
  }
`;

const StyledTitle = styled(Title)`
  color: #000;
  text-align: center;
  
  font-size: 1.25rem;
  font-weight: 300;
  padding 2rem 0rem .5rem 1rem;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const SelectorDesc = styled('p')`
  text-align: center;
  color: #000;

  font-size: 14px;
  font-weight: 300;
  margin-bottom: 1rem;
  padding: 0rem 1rem;
`;

const selectorWrapper = css`
  width: 100%;
  max-width: 100%;
`;

const UpdateButton = styled(CommitButton)`
  background: #000;

  font-size: 14px;
  font-weight: 300;
  height: 40px;
  margin-top: 2.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  text-transform: uppercase;
  transition: all ease 0.3s;
  width: 150px;
  :hover {
    background: #333;
  }
`;

const CloseButton = css`
  background: #000;
  color: #fff;

  font-size: 14px;
  font-weight: 300;
  height: 40px;
  outline: none;
  margin-top: 2.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  text-transform: uppercase;
  transition: all ease 0.3s;
  width: 150px;
  :hover {
    background: #333;
  }
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel
}) => {
  let b2c = channels.filter(
    channel => channel.name.toLowerCase().indexOf('b2b') < 0
  );

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({
        currencyItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => (
        <div className={selectorWrapper}>
          <SelectorBody>
            <StyledTitle>Change country</StyledTitle>
            <SelectorDesc>
              Select a country or region below to see available language and
              currency options
            </SelectorDesc>
            {b2c.map(channel => {
              const country = channel.countries.find(c => {
                return c.isDefault === true;
              });
              return (
                <Country
                  key={channel.id}
                  isActive={channel.id === selectedChannel.id}
                  onClick={() =>
                    onSelect(
                      channel.id,
                      channel.defaultLanguage.culture,
                      channel.defaultCurrency.format.code,
                      channel.countries[0]
                    )
                  }
                >
                  {channel.id === 3 ? (
                    <International>
                      <Globe />
                    </International>
                  ) : (
                    <CountryFlag
                      src={`https://countryflags.jetshop.io/${country.code}/flat/32.png`}
                      alt="Country Flag"
                    />
                  )}
                  {channel.id === 3 ? 'International' : country.name}
                </Country>
              );
            })}
          </SelectorBody>
          <LangCurrWrapper>
            <ChecboxGroupContainer>
              <CheckboxGropDivider>
                <CheckboxGroup
                  selectedItem={
                    selectedChannel.language.isDefault
                      ? selectedChannel.language.name
                      : selectedChannel.language.name
                  }
                  items={selectedChannel.languages
                    .map(({ isDefault, name }) => ({
                      isDefault,
                      val: name,
                      label: name
                    }))
                    .sort(a => (a.isDefault ? -1 : 1))}
                  handleChange={onLanguageSelect}
                  groupLabel={t('Language')}
                />
              </CheckboxGropDivider>
              <CheckboxGropDivider>
                <CheckboxGroup
                  selectedItem={selectedChannel.currency.name}
                  items={currencyItems}
                  handleChange={onCurrencySelect}
                  groupLabel={t('Currency')}
                />
              </CheckboxGropDivider>
            </ChecboxGroupContainer>
          </LangCurrWrapper>
          {hasChanged ? (
            <UpdateButton
              onClick={() => {
                updateChannel(selectedChannel);
                hideTarget();
              }}
              text={t('Update')}
            />
          ) : (
            <CancelButton
              className={CloseButton}
              text={t('Close')}
              onClick={() => hideTarget()}
            />
          )}
        </div>
      )}
    />
  );
};

export default Selector;
