import AddToCartFormik from '@jetshop/core/cart/AddToCartFormik';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { TrendButton } from '@jetshop/ui/Button';
import Image from '@jetshop/ui/Image';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { Form } from 'formik';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import {
  ItemDetails,
  LinePrice,
  ProductName,
  RemoveItem,
  Wrapper,
  OutOfStock,
  PriceWrapper,
  ProductDetail
} from '../Cart/CartItem';
import addToCartMutation from '../Cart/queries/addToCart.gql';
import CartQuery from '../Cart/queries/CartQuery.gql';
import { SelectVariant } from './SelectVariant';

const AddToCart = styled(TrendButton)`
  font-size: 0.8rem;
  text-transform: uppercase;
  width: auto;
`;

const FavouriteItem = ({ product, className = '', onClose }) => {
  const { remove } = useProductList();

  const outOfStock = product.variant
    ? !product.variant?.stockStatus?.buyable
    : false;

  return (
    <Wrapper className={className}>
      <div>
        {product.images.length > 0 && (
          <Image
            aspect="2:3"
            sizes="200px"
            src={product.images[0].url}
            alt={product.images[0].alt}
            quality={80}
          />
        )}
      </div>
      <ProductDetail>
        <ItemDetails>
          <ProductName>
            <Link to={product.primaryRoute.path} onClick={onClose}>
              <h2
                style={{
                  fontSize: '16px',
                  fontWeight: '400'
                }}
              >
                {product.name}
              </h2>
            </Link>

            {product.subName}
          </ProductName>
        </ItemDetails>

        <div style={{ gridArea: 'bottomleft' }}>
          {product.hasVariants && <SelectVariant product={product} />}
          {!outOfStock && (
            <AddToCartFormik
              cartQuery={CartQuery}
              cartMutation={addToCartMutation}
              product={product}
              variant={product.variant}
            >
              {() => (
                <Form>
                  <AddToCart disabled={!product.variant} type="submit">
                    {t('Add to cart')}
                  </AddToCart>
                </Form>
              )}
            </AddToCartFormik>
          )}
        </div>

        <RemoveItem>
          <button
            onClick={() =>
              remove(product.articleNumber, {
                variantArticleNumber: product?.variant?.articleNumber
              })
            }
            data-testid="remove-from-cart"
          >
            <Cross />
          </button>
        </RemoveItem>

        <PriceWrapper>
          {outOfStock ? (
            <OutOfStock>{t('Out of stock')}</OutOfStock>
          ) : (
            <LinePrice
              price={product.price}
              previousPrice={product.previousPrice}
            />
          )}
        </PriceWrapper>
      </ProductDetail>
    </Wrapper>
  );
};

export default FavouriteItem;
