import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import { theme } from './components/Theme';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import {
  LoadableProductPage,
  LoadableContentPage,
  LoadableCategoryPage
} from './components/Shop';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'bnb',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.se',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: {
          levels: 1
        }
      }
    ]
  },
  serverTrackers: [
    createFacebookTracker({
      pixelId: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN
    })
  ],
  relewareEnabled: false,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {}
  },
  loginPath: '/login',
  channelOverrides: {},
  disableGeoRedirect: false,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  deprecations: { useOldProductVariants: false, useOldFilterAPI: false },
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [LoadableProductPage],
      Category: [LoadableCategoryPage],
      Page: [LoadableContentPage]
    }
  }
};

export default config;
