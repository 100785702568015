import React from 'react';
import { styled } from 'linaria/react';
import { useYotpoWidget } from './Yotpo';
import YotpoUGC from './YotpoUGC';

const SEOPage = styled('div')`
  width: 100%;
  max-width: 1220px;
  padding: 0 20px;
  margin: auto;
  margin-top: 50px;
`;

export const YotpoSeoPage = ({ product }) => {
  const loaded = useYotpoWidget();

  return (
    <>
      <div className="yotpo-reviews" />
      {loaded ? (
        <SEOPage>
          <YotpoUGC galleryId="6357b29c4d372c001a194e96" />
          <div id="yotpo-testimonials-custom-tab"></div>
        </SEOPage>
      ) : (
        ''
      )}
    </>
  );
};

export default YotpoSeoPage;
