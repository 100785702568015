const getStandardShippingCost = channelId => {
  switch (channelId) {
    // SE, NO
    case 1:
    case 4:
      return 59;
    // DK
    case 2:
      return 39;
    // US
    case 7:
      return 16;
    default:
      return 5.95;
  }
};

export default getStandardShippingCost;
