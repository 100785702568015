// because of some issues with cookie we need to test
// if a script is loaded even if it says its loaded.. i know..

import { useEffect, useState } from 'react';

export const useWaitForTrue = fn => {
  const [done, setDone] = useState(fn);
  useEffect(() => {
    if (done) return;
    const iv = setInterval(() => {
      setDone(!!fn());
    }, 100);
    return () => clearInterval(iv);
  }, [fn, done]);
  return done;
};
