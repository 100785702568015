import { useProductList } from '@jetshop/core/hooks/ProductList';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import t from '@jetshop/intl';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Check } from '@jetshop/ui/svg/Check.svg';
import { styled } from 'linaria/react';
import React, { useEffect } from 'react';
import { ReactComponent as AngleDown } from '../../svg/angle-down.svg';

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-bottom: 0.5rem;
`;

const StyledDropdownMenuButton = styled(DropdownMenuButton)`
  background: #fff !important;
  border-radius: 0 !important;
  border-color: #ddd !important;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 0.25rem;
  outline: none;
  text-align: left;
  svg {
    width: 10px;
    margin-left: 5px;
  }
`;

const StyledDropdownMenuItems = styled(DropdownMenuItems)`
  border: 1px solid #ddd;
  border-top: 0 !important;
  margin-top: -1px;
  width: 100px;
`;

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  font-size: 12px;
  outline: none;
  transition: all ease 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color #000 !important;

  svg {
    path {
        fill: #000;
    }
  }

  :hover {
    background: #f3f3f3;
  }
`;

export function SelectVariant({ product }) {
  const { update } = useProductList();
  const initialVariant = product.variant;

  const {
    selectedVariant,
    selectValue,
    getSelectedValue,
    validateSelection
  } = useProductVariants(product, {
    initialVariant
  });

  useEffect(() => {
    // When a variant is selected, call the update function from useProductList to update the list
    function setVariant(variantArticleNumber) {
      update(product.articleNumber, {
        variantArticleNumber,
        variantToReplace: product.variant?.articleNumber,
        quantity: 1
      });
    }

    const currentArticleNumber = product.variant?.articleNumber;

    if (!selectedVariant) return;

    if (currentArticleNumber !== selectedVariant.articleNumber) {
      setVariant(selectedVariant.articleNumber);
    }
  }, [product.articleNumber, product.variant, selectedVariant, update]);

  return (
    <>
      {product.variants.options.map(option => {
        const selectedValue = getSelectedValue(option);
        return (
          <StyledDropdownMenu key={option.name}>
            <StyledDropdownMenuButton>
              <span>
                {selectedValue
                  ? `${option.name}: ${selectedValue}`
                  : t.rich('Please select {option}', { option: option.name })}
              </span>
              <span>
                <AngleDown />
              </span>
            </StyledDropdownMenuButton>
            <StyledDropdownMenuItems style={{ zIndex: 9999 }}>
              {option.values.map(value => {
                const validation = validateSelection(value, option);

                return (
                  <StyledDropdownMenuItem
                    key={value}
                    disabled={validation !== 'valid'}
                    closeOnSelect={true}
                    onSelect={() => {
                      selectValue(value, option);
                    }}
                    style={{ opacity: validation === 'valid' ? 1 : 0.5 }}
                  >
                    {value}
                    {selectedValue === value && <Check />}
                  </StyledDropdownMenuItem>
                );
              })}
            </StyledDropdownMenuItems>
          </StyledDropdownMenu>
        );
      })}
    </>
  );
}
