import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Star } from '../../svg/star-solid.svg';

export const RatingStar = styled(Star)`
  font-size: 0.8rem;
  margin: 0 2px;
  color: #000;

  &.grey {
    color: #ddd;
  }

  &.notComplete {
    path {
      fill: url(#linear);
    }
  }
`;

export const RatingStars = ({ stars }) => {
  let result = [];
  for (let i = 0; i < 5; ++i) {
    const cls =
      stars >= i + 0.75 ? '' : stars >= i + 0.25 ? 'notComplete' : 'grey';
    result.push(<RatingStar key={i} className={cls} />);
  }
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop stopColor="#000" offset="0" />
          <stop stopColor="#000" offset="50%" />
          <stop stopColor="#ddd" offset="50%" />
          <stop stopColor="#ddd" offset="100%" />
        </linearGradient>
      </svg>
      {result}
    </>
  );
};
