import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React from 'react';
import { ReactComponent as Search } from '../../../svg/Search.svg';

const Button = styled('button')`
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: inherit;
  outline: none;
  width: 30%;

  svg {
    margin-bottom: 0;
    height: 15px;
    use {
      fill: #000;
    }
  }
  span {
    color: #000;

    font-size: 15px;
    font-weight: 300;
    margin-left: 5px;
  }
`;

function SearchButton(props) {
  const { setSearchOpen } = props;
  return (
    <Button onClick={setSearchOpen}>
      <Search />
      <span>{t('Search')}</span>
    </Button>
  );
}

export default SearchButton;
